.App {
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 16px;
  font-weight: 400;
}

.container {
  width: 70%;
  height: 100%;
  border: 3px solid black;
  background-color: lightgrey;
}

hr {
  width: 70%;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
button {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 70px;
  background-color: black;
  color: white;
  border: 2px solid white;
  border-radius: 5%;

}

.etherbalance {
  font-weight: bold;
  color: red;
}

